<script setup>
import { storeToRefs } from 'pinia';
import { useMessageStore } from '~/stores/messages';
import IlluSpark02 from '@/assets/illustrations/illu_spark_02.svg?component';
import LinkIos from '@/assets/logos/ios.svg?url';
import LinkAndroid from '@/assets/logos/android.svg?url';
import { useCenterStore } from '~/stores/centerDetails';
import { trackUserClick } from '~/composables/useTracking';

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

/**
 * Tracks a click event in the application store.
 *
 * @param {string} store - The name of the store to track.
 * @return {void} This function does not return a value.
 */

const trackClick = (store) => {
    trackUserClick('Appstore_Click', {
        element_context: 'footer',
        app_provider: store
    });
}

// Get center route
const { centerRoute } = storeToRefs(useCenterStore());
</script>

<template>
    <footer class="page-default-footer">
        <div class="page-default-footer-innerwrap">
            <PageLogo class="page-default-footer-logo" />

            <nav class="page-default-footer-legal">
                <ul>
                    <li>
                        <NuxtLink :to="centerRoute ? `/${centerRoute}/faq` : '/faq'">
                            {{ staticKeys?.menu_faq }}
                        </NuxtLink>
                    </li>
                    <li>
                        <NuxtLink :to="centerRoute ? `/${centerRoute}/datenschutz` : '/datenschutz'">
                            {{ staticKeys?.menu_dataprotection }}
                        </NuxtLink>
                    </li>
                    <li>
                        <NuxtLink :to="centerRoute ? `/${centerRoute}/rechtliches` : '/rechtliches'">
                            {{ staticKeys?.menu_legal }}
                        </NuxtLink>
                    </li>
                    <li>
                        <NuxtLink :to="centerRoute ? `/${centerRoute}/impressum` : '/impressum'">
                            {{ staticKeys?.menu_imprint }}
                        </NuxtLink>
                    </li>
                    <li>
                        <NuxtLink :to="centerRoute ? `/${centerRoute}/agb` : '/agb'">
                            {{ staticKeys?.menu_agb }}
                        </NuxtLink>
                    </li>
                    <!-- <li>
                        <span class="page-footer-legal-cmp" @click="showCMP">{{ staticKeys?.menu_cookies}}</span>
                    </li> -->
                </ul>
            </nav>
            <div class="page-default-footer-illu">
                <IlluSpark02 />
            </div>

            <div class="page-default-footer-appstore-teaser">
                <h2>{{ staticKeys?.footer_appteaser_headline_web }}</h2>
                <ul class="page-default-footer-appstore-list">
                    <li>
                        <NuxtLink
                            :to="staticKeys?.account_gettheapp_ios_link_footer"
                            :title="`${staticKeys?.footer_appteaser_ios_alt_web }`"
                            @click="trackClick('app_store')"
                        >
                            <img
                                :src="LinkIos" :alt="staticKeys?.footer_appteaser_ios_alt_web" width="140"
                                height="42"
                            >
                        </NuxtLink>
                    </li>
                    <li>
                        <NuxtLink
                            :to="staticKeys?.account_gettheapp_android_link_footer"
                            :title="`${staticKeys?.footer_appteaser_android_alt_web }`"
                            @click="trackClick('google_play')"
                        >
                            <img
                                :src="LinkAndroid" :alt="staticKeys?.footer_appteaser_android_alt_web" width="140"
                                height="42"
                            >
                        </NuxtLink>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<style lang="scss">
.page-default-footer {
    @include fullbleed;

    background: $page-default-footer-bg;

    a {
        color: inherit;
        text-decoration: none;
    }
}

.page-default-footer-innerwrap {
    @include content-grid;

    grid-column: 2 / -2;
    gap: 0;
    padding: px2rem(32);
    padding-block-start: px2rem(60);
    color: $page-default-footer-txt;
    font-size: px2rem(14);

    @include bp(medium1) {
        gap: 0;
        padding-inline: 0;
        padding-block-start: 2rem;
        align-items: end;
    }

    @include bp(large1) {
        padding-block: px2rem(25);
    }
}

.page-default-footer-logo {
    display: none;
    z-index: unset;

    @include bp(medium1) {
        grid-column: 1;
        display: flex;
        align-items: flex-end;
    }

    @include bp(large1) {
        display: none;
    }
}

.page-default-footer-legal {
    grid-column: 1 / -1;
    margin-block-end: px2rem(40);

    @include bp(medium1) {
        grid-column: 2 / -1;
        margin-block-end: 0;
    }

    @include bp(large1) {
        grid-column: 1 / -1;
    }
}

.page-default-footer-legal ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    @include bp(medium1) {
        flex-direction: row;
        justify-content: flex-end;
        gap: px2rem(28);
    }

    @include bp(large1) {
        justify-content: flex-start;
        gap: px2rem(64);
    }

    li {
        a,
        span {
            @include body1-medium;

            text-transform: uppercase;

            @include bp(medium1) {
                @include body2-medium;
            }

            &.router-link-active {
                color: $page-default-footer-link-current;
            }

            @media (hover: hover) {
                &:hover {
                    color: $page-default-footer-link-hover;
                    cursor: pointer;
                }
            }

            @media (hover: none) {
                a:active {
                    color: $page-default-footer-link-current;
                }
            }
        }
    }
}

.page-default-footer-illu {
    grid-column: -1 / -2;
    grid-row: 2;
    // position: absolute;

    @include bp(medium1) {
        grid-column: 4;
        justify-self: end;
        padding-block-start: 2rem;
    }

    @include bp(large1) {
        display: none;
    }

    svg {
        width: px2rem(32);
        height: auto
    }

}

.page-default-footer-appstore-teaser {
    grid-column: 1 / -1;

    @include bp(large1) {
        display: none;
    }

    h2 {
        font-family: $font-headline;
        font-style: italic;
        font-weight: 500;
        font-size: px2rem(20);
        line-height: px2rem(24);
        letter-spacing: -0.05%;
        text-align: center;
        margin-block: 0 px2rem(40);

        @include bp(medium1) {
            display: none;
        }
    }
}

.page-default-footer-appstore-list {
    display: flex;
    gap: 1rem;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;

    @include bp(medium1) {
        justify-content: flex-start
    }

    img {
        width: 140px;
        height: auto;
    }
}
</style>
