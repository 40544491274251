import { default as agba32uzdIh6QMeta } from "/usr/src/app/src/pages/[centerUid]/agb.vue?macro=true";
import { default as _91couponUid_93odRhUVPHuSMeta } from "/usr/src/app/src/pages/[centerUid]/coupons/[couponUid].vue?macro=true";
import { default as indexkXuDfUHWgRMeta } from "/usr/src/app/src/pages/[centerUid]/coupons/index.vue?macro=true";
import { default as datenschutzH4BUbsH73WMeta } from "/usr/src/app/src/pages/[centerUid]/datenschutz.vue?macro=true";
import { default as _91eventsUid_93eJDj1QKXkJMeta } from "/usr/src/app/src/pages/[centerUid]/events/[eventsUid].vue?macro=true";
import { default as indexXuFJhMm134Meta } from "/usr/src/app/src/pages/[centerUid]/events/index.vue?macro=true";
import { default as faqX33KzeauyVMeta } from "/usr/src/app/src/pages/[centerUid]/faq.vue?macro=true";
import { default as impressum1luvtlpNbZMeta } from "/usr/src/app/src/pages/[centerUid]/impressum.vue?macro=true";
import { default as indexXPHnPKceixMeta } from "/usr/src/app/src/pages/[centerUid]/index.vue?macro=true";
import { default as _91newsUid_93mamtmETrDFMeta } from "/usr/src/app/src/pages/[centerUid]/news/[newsUid].vue?macro=true";
import { default as indexgo3ALEiKzXMeta } from "/usr/src/app/src/pages/[centerUid]/news/index.vue?macro=true";
import { default as parkenxXG8XL6f5lMeta } from "/usr/src/app/src/pages/[centerUid]/parken.vue?macro=true";
import { default as rechtlicheszJPad05lg4Meta } from "/usr/src/app/src/pages/[centerUid]/rechtliches.vue?macro=true";
import { default as couponsaoDKvWh8J3Meta } from "/usr/src/app/src/pages/[centerUid]/shops/[shopUid]/coupons.vue?macro=true";
import { default as indexPedeY9O0SKMeta } from "/usr/src/app/src/pages/[centerUid]/shops/[shopUid]/index.vue?macro=true";
import { default as indexgbUFBmaX2IMeta } from "/usr/src/app/src/pages/[centerUid]/shops/index.vue?macro=true";
import { default as shops1eUwtwqQCGMeta } from "/usr/src/app/src/pages/[centerUid]/shops.vue?macro=true";
import { default as agbihByROA2LlMeta } from "/usr/src/app/src/pages/agb.vue?macro=true";
import { default as authTJEXAOe4vXMeta } from "/usr/src/app/src/pages/auth.vue?macro=true";
import { default as datenschutzKkS7Q4RWUQMeta } from "/usr/src/app/src/pages/datenschutz.vue?macro=true";
import { default as faqlAaQg9OQGTMeta } from "/usr/src/app/src/pages/faq.vue?macro=true";
import { default as iframewM6bW6SVZ2Meta } from "/usr/src/app/src/pages/iframe.vue?macro=true";
import { default as impressumEwkgBxQF0NMeta } from "/usr/src/app/src/pages/impressum.vue?macro=true";
import { default as indexYu3DynwDClMeta } from "/usr/src/app/src/pages/index.vue?macro=true";
import { default as logoutAhPWW22ABKMeta } from "/usr/src/app/src/pages/logout.vue?macro=true";
import { default as rechtlichesFPxsfWNIbKMeta } from "/usr/src/app/src/pages/rechtliches.vue?macro=true";
import { default as unsubscribe_45errorUd71VnFZyOMeta } from "/usr/src/app/src/pages/unsubscribe-error.vue?macro=true";
import { default as unsubscribe_45noaccountpCfjiofP00Meta } from "/usr/src/app/src/pages/unsubscribe-noaccount.vue?macro=true";
import { default as unsubscribe_45successiiKViE9bsuMeta } from "/usr/src/app/src/pages/unsubscribe-success.vue?macro=true";
import { default as personal_45databIICo6LiXGMeta } from "/usr/src/app/src/pages/webviews/personal-data.vue?macro=true";
import { default as personal_45preferences7KQ2cTr08HMeta } from "/usr/src/app/src/pages/webviews/personal-preferences.vue?macro=true";
export default [
  {
    name: agba32uzdIh6QMeta?.name ?? "centerUid-agb",
    path: agba32uzdIh6QMeta?.path ?? "/:centerUid()/agb",
    meta: agba32uzdIh6QMeta || {},
    alias: agba32uzdIh6QMeta?.alias || [],
    redirect: agba32uzdIh6QMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/agb.vue").then(m => m.default || m)
  },
  {
    name: _91couponUid_93odRhUVPHuSMeta?.name ?? "centerUid-coupons-couponUid",
    path: _91couponUid_93odRhUVPHuSMeta?.path ?? "/:centerUid()/coupons/:couponUid()",
    meta: _91couponUid_93odRhUVPHuSMeta || {},
    alias: _91couponUid_93odRhUVPHuSMeta?.alias || [],
    redirect: _91couponUid_93odRhUVPHuSMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/coupons/[couponUid].vue").then(m => m.default || m)
  },
  {
    name: indexkXuDfUHWgRMeta?.name ?? "centerUid-coupons",
    path: indexkXuDfUHWgRMeta?.path ?? "/:centerUid()/coupons",
    meta: indexkXuDfUHWgRMeta || {},
    alias: indexkXuDfUHWgRMeta?.alias || [],
    redirect: indexkXuDfUHWgRMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: datenschutzH4BUbsH73WMeta?.name ?? "centerUid-datenschutz",
    path: datenschutzH4BUbsH73WMeta?.path ?? "/:centerUid()/datenschutz",
    meta: datenschutzH4BUbsH73WMeta || {},
    alias: datenschutzH4BUbsH73WMeta?.alias || [],
    redirect: datenschutzH4BUbsH73WMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: _91eventsUid_93eJDj1QKXkJMeta?.name ?? "centerUid-events-eventsUid",
    path: _91eventsUid_93eJDj1QKXkJMeta?.path ?? "/:centerUid()/events/:eventsUid()",
    meta: _91eventsUid_93eJDj1QKXkJMeta || {},
    alias: _91eventsUid_93eJDj1QKXkJMeta?.alias || [],
    redirect: _91eventsUid_93eJDj1QKXkJMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/events/[eventsUid].vue").then(m => m.default || m)
  },
  {
    name: indexXuFJhMm134Meta?.name ?? "centerUid-events",
    path: indexXuFJhMm134Meta?.path ?? "/:centerUid()/events",
    meta: indexXuFJhMm134Meta || {},
    alias: indexXuFJhMm134Meta?.alias || [],
    redirect: indexXuFJhMm134Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/events/index.vue").then(m => m.default || m)
  },
  {
    name: faqX33KzeauyVMeta?.name ?? "centerUid-faq",
    path: faqX33KzeauyVMeta?.path ?? "/:centerUid()/faq",
    meta: faqX33KzeauyVMeta || {},
    alias: faqX33KzeauyVMeta?.alias || [],
    redirect: faqX33KzeauyVMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/faq.vue").then(m => m.default || m)
  },
  {
    name: impressum1luvtlpNbZMeta?.name ?? "centerUid-impressum",
    path: impressum1luvtlpNbZMeta?.path ?? "/:centerUid()/impressum",
    meta: impressum1luvtlpNbZMeta || {},
    alias: impressum1luvtlpNbZMeta?.alias || [],
    redirect: impressum1luvtlpNbZMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexXPHnPKceixMeta?.name ?? "centerUid",
    path: indexXPHnPKceixMeta?.path ?? "/:centerUid()",
    meta: indexXPHnPKceixMeta || {},
    alias: indexXPHnPKceixMeta?.alias || [],
    redirect: indexXPHnPKceixMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/index.vue").then(m => m.default || m)
  },
  {
    name: _91newsUid_93mamtmETrDFMeta?.name ?? "centerUid-news-newsUid",
    path: _91newsUid_93mamtmETrDFMeta?.path ?? "/:centerUid()/news/:newsUid()",
    meta: _91newsUid_93mamtmETrDFMeta || {},
    alias: _91newsUid_93mamtmETrDFMeta?.alias || [],
    redirect: _91newsUid_93mamtmETrDFMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/news/[newsUid].vue").then(m => m.default || m)
  },
  {
    name: indexgo3ALEiKzXMeta?.name ?? "centerUid-news",
    path: indexgo3ALEiKzXMeta?.path ?? "/:centerUid()/news",
    meta: indexgo3ALEiKzXMeta || {},
    alias: indexgo3ALEiKzXMeta?.alias || [],
    redirect: indexgo3ALEiKzXMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/news/index.vue").then(m => m.default || m)
  },
  {
    name: parkenxXG8XL6f5lMeta?.name ?? "centerUid-parken",
    path: parkenxXG8XL6f5lMeta?.path ?? "/:centerUid()/parken",
    meta: parkenxXG8XL6f5lMeta || {},
    alias: parkenxXG8XL6f5lMeta?.alias || [],
    redirect: parkenxXG8XL6f5lMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/parken.vue").then(m => m.default || m)
  },
  {
    name: rechtlicheszJPad05lg4Meta?.name ?? "centerUid-rechtliches",
    path: rechtlicheszJPad05lg4Meta?.path ?? "/:centerUid()/rechtliches",
    meta: rechtlicheszJPad05lg4Meta || {},
    alias: rechtlicheszJPad05lg4Meta?.alias || [],
    redirect: rechtlicheszJPad05lg4Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/rechtliches.vue").then(m => m.default || m)
  },
  {
    path: shops1eUwtwqQCGMeta?.path ?? "/:centerUid()/shops",
    children: [
  {
    name: couponsaoDKvWh8J3Meta?.name ?? "centerUid-shops-shopUid-coupons",
    path: couponsaoDKvWh8J3Meta?.path ?? ":shopUid()/coupons",
    meta: couponsaoDKvWh8J3Meta || {},
    alias: couponsaoDKvWh8J3Meta?.alias || [],
    redirect: couponsaoDKvWh8J3Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops/[shopUid]/coupons.vue").then(m => m.default || m)
  },
  {
    name: indexPedeY9O0SKMeta?.name ?? "centerUid-shops-shopUid",
    path: indexPedeY9O0SKMeta?.path ?? ":shopUid()",
    meta: indexPedeY9O0SKMeta || {},
    alias: indexPedeY9O0SKMeta?.alias || [],
    redirect: indexPedeY9O0SKMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops/[shopUid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbUFBmaX2IMeta?.name ?? "centerUid-shops",
    path: indexgbUFBmaX2IMeta?.path ?? "",
    meta: indexgbUFBmaX2IMeta || {},
    alias: indexgbUFBmaX2IMeta?.alias || [],
    redirect: indexgbUFBmaX2IMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops/index.vue").then(m => m.default || m)
  }
],
    name: shops1eUwtwqQCGMeta?.name ?? undefined,
    meta: shops1eUwtwqQCGMeta || {},
    alias: shops1eUwtwqQCGMeta?.alias || [],
    redirect: shops1eUwtwqQCGMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops.vue").then(m => m.default || m)
  },
  {
    name: agbihByROA2LlMeta?.name ?? "agb",
    path: agbihByROA2LlMeta?.path ?? "/agb",
    meta: agbihByROA2LlMeta || {},
    alias: agbihByROA2LlMeta?.alias || [],
    redirect: agbihByROA2LlMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/agb.vue").then(m => m.default || m)
  },
  {
    name: authTJEXAOe4vXMeta?.name ?? "auth",
    path: authTJEXAOe4vXMeta?.path ?? "/auth",
    meta: authTJEXAOe4vXMeta || {},
    alias: authTJEXAOe4vXMeta?.alias || [],
    redirect: authTJEXAOe4vXMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: datenschutzKkS7Q4RWUQMeta?.name ?? "datenschutz",
    path: datenschutzKkS7Q4RWUQMeta?.path ?? "/datenschutz",
    meta: datenschutzKkS7Q4RWUQMeta || {},
    alias: datenschutzKkS7Q4RWUQMeta?.alias || [],
    redirect: datenschutzKkS7Q4RWUQMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: faqlAaQg9OQGTMeta?.name ?? "faq",
    path: faqlAaQg9OQGTMeta?.path ?? "/faq",
    meta: faqlAaQg9OQGTMeta || {},
    alias: faqlAaQg9OQGTMeta?.alias || [],
    redirect: faqlAaQg9OQGTMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: iframewM6bW6SVZ2Meta?.name ?? "iframe",
    path: iframewM6bW6SVZ2Meta?.path ?? "/iframe",
    meta: iframewM6bW6SVZ2Meta || {},
    alias: iframewM6bW6SVZ2Meta?.alias || [],
    redirect: iframewM6bW6SVZ2Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/iframe.vue").then(m => m.default || m)
  },
  {
    name: impressumEwkgBxQF0NMeta?.name ?? "impressum",
    path: impressumEwkgBxQF0NMeta?.path ?? "/impressum",
    meta: impressumEwkgBxQF0NMeta || {},
    alias: impressumEwkgBxQF0NMeta?.alias || [],
    redirect: impressumEwkgBxQF0NMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexYu3DynwDClMeta?.name ?? "index",
    path: indexYu3DynwDClMeta?.path ?? "/",
    meta: indexYu3DynwDClMeta || {},
    alias: indexYu3DynwDClMeta?.alias || [],
    redirect: indexYu3DynwDClMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logoutAhPWW22ABKMeta?.name ?? "logout",
    path: logoutAhPWW22ABKMeta?.path ?? "/logout",
    meta: logoutAhPWW22ABKMeta || {},
    alias: logoutAhPWW22ABKMeta?.alias || [],
    redirect: logoutAhPWW22ABKMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: rechtlichesFPxsfWNIbKMeta?.name ?? "rechtliches",
    path: rechtlichesFPxsfWNIbKMeta?.path ?? "/rechtliches",
    meta: rechtlichesFPxsfWNIbKMeta || {},
    alias: rechtlichesFPxsfWNIbKMeta?.alias || [],
    redirect: rechtlichesFPxsfWNIbKMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/rechtliches.vue").then(m => m.default || m)
  },
  {
    name: unsubscribe_45errorUd71VnFZyOMeta?.name ?? "unsubscribe-error",
    path: unsubscribe_45errorUd71VnFZyOMeta?.path ?? "/unsubscribe-error",
    meta: unsubscribe_45errorUd71VnFZyOMeta || {},
    alias: unsubscribe_45errorUd71VnFZyOMeta?.alias || [],
    redirect: unsubscribe_45errorUd71VnFZyOMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/unsubscribe-error.vue").then(m => m.default || m)
  },
  {
    name: unsubscribe_45noaccountpCfjiofP00Meta?.name ?? "unsubscribe-noaccount",
    path: unsubscribe_45noaccountpCfjiofP00Meta?.path ?? "/unsubscribe-noaccount",
    meta: unsubscribe_45noaccountpCfjiofP00Meta || {},
    alias: unsubscribe_45noaccountpCfjiofP00Meta?.alias || [],
    redirect: unsubscribe_45noaccountpCfjiofP00Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/unsubscribe-noaccount.vue").then(m => m.default || m)
  },
  {
    name: unsubscribe_45successiiKViE9bsuMeta?.name ?? "unsubscribe-success",
    path: unsubscribe_45successiiKViE9bsuMeta?.path ?? "/unsubscribe-success",
    meta: unsubscribe_45successiiKViE9bsuMeta || {},
    alias: unsubscribe_45successiiKViE9bsuMeta?.alias || [],
    redirect: unsubscribe_45successiiKViE9bsuMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/unsubscribe-success.vue").then(m => m.default || m)
  },
  {
    name: personal_45databIICo6LiXGMeta?.name ?? "webviews-personal-data",
    path: personal_45databIICo6LiXGMeta?.path ?? "/webviews/personal-data",
    meta: personal_45databIICo6LiXGMeta || {},
    alias: personal_45databIICo6LiXGMeta?.alias || [],
    redirect: personal_45databIICo6LiXGMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/webviews/personal-data.vue").then(m => m.default || m)
  },
  {
    name: personal_45preferences7KQ2cTr08HMeta?.name ?? "webviews-personal-preferences",
    path: personal_45preferences7KQ2cTr08HMeta?.path ?? "/webviews/personal-preferences",
    meta: personal_45preferences7KQ2cTr08HMeta || {},
    alias: personal_45preferences7KQ2cTr08HMeta?.alias || [],
    redirect: personal_45preferences7KQ2cTr08HMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/webviews/personal-preferences.vue").then(m => m.default || m)
  }
]