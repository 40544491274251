<template>
    <header class="page-default-header">
        <div class="page-default-header-innerwrap">
            <PageLogo />
            <CenterNavigation />
        </div>
    </header>
</template>

<style lang="scss">
.page-default-header {
  @include fullbleed;

  align-items: center;
  background-color: transparent;
  margin-block: px2rem(23) px2rem(40);
  font-size: px2rem(14);
  isolation: isolate;
  position: relative;
  z-index: z('page-default-header');

  @include bp(medium1) {
    margin-block-start: 0;
    background-color: $page-default-header-bg;
  }

  @include bp(large1) {
    margin-block-end: px2rem(60);
  }

  &:has(.page-mainnavigation-open) {
    position: fixed;
    z-index: z('page-default-header-menu-open');
    top: 0;
  }

  .page-default-header-innerwrap {
    @include content-grid;

    align-items: center;

    @include bp(medium1) {
      height: px2rem(56);
    }
  }
}
</style>
