/**
 * @param {string} eventName
 * @param {object} eventParam
 */

const trackUserClick = (eventName, eventParam) => {
    if (process.client) {
        const config = useRuntimeConfig();
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push(() => {
            window.google_tag_manager[config.public.gtmContainerId].dataLayer.reset()
        })

        window.dataLayer.push({
            event: eventName,
            ...eventParam
        });
    }
}

/**
 * @param {String} customPageTitle
 * @param {Object} addParams
 */
const trackPageLoad = (customPageTitle, addParams = {}) => {
    if (process.client) {
        const config = useRuntimeConfig();
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push(() => {
            window.google_tag_manager[config.public.gtmContainerId].dataLayer.reset()
        })

        window.dataLayer.push({
            event: 'page_view_meta',
            custom_page_title: customPageTitle,
            ...addParams
        });
    }
}

/**
 * Tracks user meta-data
 *
 * @param {Object} addParams - Additional parameters to include in the event.
 */
const trackUserMeta = (addParams = {}) => {
    if (process.client) {
        const config = useRuntimeConfig();
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push(() => {
            window.google_tag_manager[config.public.gtmContainerId].dataLayer.reset()
        })

        window.dataLayer.push({
            event: 'user_info_meta',
            ...addParams
        });
    }
}

export {
    trackUserClick,
    trackPageLoad,
    trackUserMeta
}
