import { defineStore } from 'pinia';

export const useCenterStore = defineStore('center', () => {
        // Current center details obj
        const _centerDetails = ref(null);
        const centerDetails = computed(() => _centerDetails.value);

        const _centerUid = ref(null);
        const centerUid = computed(() => _centerUid.value?.toUpperCase());

        const _centerRoute = ref(null);
        const centerRoute = computed(() => _centerRoute.value);

        const _centerName = ref(null);
        const centerName = computed(() => _centerName.value);

        /**
         * Updates the center details based on the given payload.
         *
         * @param {Object} payload - The payload containing the updated center details.
         */
        const updateCenterDetails = (payload) => {
            if (payload) {
                const { uid } = payload;
                const { name } = payload;

                if (_centerDetails.value) {
                    _centerDetails.value = {
                        ...centerDetails.value,
                        ...payload
                    }

                    _centerUid.value = uid;
                    _centerRoute.value = uid;
                    _centerName.value = name;
                } else {
                    _centerDetails.value = payload || null;
                    _centerUid.value = uid;
                    _centerRoute.value = uid;
                    _centerName.value = name;
                }
            } else {
                _centerDetails.value = null;
                _centerName.value = null;
                _centerUid.value = null;
                _centerRoute.value = null;
            }
        };

        return {
            centerDetails,
            centerUid,
            centerRoute,
            centerName,
            updateCenterDetails
        };
    }
);
