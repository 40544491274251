import { defineStore, storeToRefs } from 'pinia';
import { useWebview } from '~/stores/webview';
import { useCenterStore } from '~/stores/centerDetails';
import { trackUserClick } from '~/composables/useTracking';

export const useSideBar = defineStore(
    'globalStates',
    () => {
        // Webview
        const webViewStore = useWebview();
        const { webview } = storeToRefs(webViewStore);

        // Sidebar
        const sideBarContent = ref({
            open: false
        });

        /**
         * Toggles the sidebar content and updates the sidebar fixed class.
         *
         * @param {Object} payload - The payload containing the updated sidebar content.
         */
        const toggleSideBar = (payload) => {
            sideBarContent.value = {
                ...sideBarContent.value,
                ...payload
            }

            setFixedClass(sideBarContent.value?.open);

            // Track click on close sidebar if signup process is present
            const { centerUid, centerName } = storeToRefs(useCenterStore());

            if (!sideBarContent.value?.open && sideBarContent.value?.content === 'signUp') {
                trackUserClick('User_Onboarding', {
                    center_id: centerUid.value,
                    center_name: centerName.value,
                    step_id: '1',
                    step_action: sideBarContent.value?.tracking === 'later' ? 'later' : 'cancel'
                });
            }
        }

        // Sidebar Overlay
        const sideBarOverlayContent = ref({
            open: false
        });
        /**
         * Toggles the sidebar content and updates the sidebar fixed class.
         *
         * @param {Object} payload - The payload containing the updated sidebar content.
         */
        const toggleSideBarOverlay = (payload) => {
            sideBarOverlayContent.value = {
                ...sideBarOverlayContent.value,
                ...payload
            }

            setFixedClass(sideBarOverlayContent.value?.open);
        }

        // Subnav
        const sideBarSubNavContent = ref({
            open: false
        });

        /**
         * Toggles the sidebar sub-navigation content and updates the state.
         *
         * @param {Object} payload - The payload to update the sidebar sub-navigation content.
         * @param {boolean} toggleParent - Optional. Determines whether to toggle the parent sidebar as well. Defaults to true.
         * @return {void}
         */
        const toggleSideBarSubNav = (payload, toggleParent = true) => {
            sideBarSubNavContent.value = {
                ...sideBarSubNavContent.value,
                ...payload
            }

            if (toggleParent) {
                // Open sidebar
                toggleSideBar({ open: !sideBarSubNavContent.value.open });
            }

            setFixedClass(true);
        };

        // Expand sidebar
        const _expandedSidebar = ref(false);

        const expandedSidebar = computed(() => _expandedSidebar.value)
        const expandSidebar = (expand) => {
            _expandedSidebar.value = expand;
        }

        // Fixed class
        const setFixedClass = (value) => {
            if (process.client) {
                const body = document.querySelector('body');

                if (value && !webview.value && body) {
                    body.classList.add('body-fixed');
                } else {
                    body.classList.remove('body-fixed');
                }
            }
        }

        const sideBarLayerOpen = ref(false);

        /**
         * Toggles the sidebar layer open state.
         *
         * @param {type} payload - the payload to set the sidebar layer open state
         * @return {undefined}
         */
        const toggleSideBarLayer = (payload) => {
            sideBarLayerOpen.value = payload;
            setFixedClass(sideBarLayerOpen.value);
        }

        return {
            sideBarContent,
            sideBarSubNavContent,
            sideBarOverlayContent,
            toggleSideBar,
            toggleSideBarSubNav,
            toggleSideBarOverlay,
            sideBarLayerOpen,
            toggleSideBarLayer,
            expandSidebar,
            expandedSidebar
        };
    }
);
