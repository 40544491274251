import { trackPageLoad } from '~/composables/useTracking';

/**
 * Inserts an ellipsis after the last word that fits within the specified character count.
 *
 * @param {string} sentence - The sentence to truncate.
 * @param {number} charCount - The maximum character count.
 * @return {string} The truncated sentence with an ellipsis appended.
 */
const insertEllipsisAfterWords = (sentence, charCount) => {
    if (!sentence) {
        return '';
    }

    // Check if the sentence length is less than or equal to charCount
    if (sentence.length <= charCount) {
        return sentence;
    }

    return sentence.substring(0, charCount) + '...';
}

/**
 * Generates a formatted tracking data string based on the provided form values.
 *
 * @param {Object} formValues - An object containing the form values.
 * @return {string} The formatted tracking data string.
 */
const getFormattedTrackingData = (formValues) => {
    // Make array with objects + filter out any values that are empty or null
    const formObj = Object.entries(formValues).map((array) => {
        return {
            [array[0]]: array[1] ? array[1] : ''
        }
    }).filter((item) => {
        return objContainsValues(item)
    })

    const keyOrder = [
        'lastName',
        'firstname',
        'gender',
        'birthday',
        'zip',
        'preferences'
    ];

    const sortedObj = sortObjects(formObj, keyOrder);

    const sortedValuesArr = sortedObj.map((obj) => {
        return Object.values(obj)
    })

    const string = sortedValuesArr.join('|');
    return string.substring(0, string.length);
}

/**
 * Transforms an object by creating a new object with the same properties as the input object, and performs some modifications.
 *
 * @param {Object} values - The input object to be transformed.
 * @return {Object} The transformed object.
 */
const transformObj = (values) => {
    for (const key in values) {
        if (typeof values[key] === 'string' && values[key] === '') {
            values[key] = null;
        }
    }

    const filteredObj = Object.keys(values).reduce((acc, key) => {
        const value = values[key];
        if ((Array.isArray(value) ? value.length > 0 : value !== undefined)) {
            acc[key] = value;
        }
        return acc;
    }, {});

    // Copy the input object
    const includesPostalCode = Object.keys(filteredObj)?.includes('postalCode');
    const includesPreferences = Object.keys(filteredObj)?.includes('preferences');
    const obj = { ...filteredObj };

    if (includesPostalCode) {
        obj.billingAddress = {
            postalCode: obj.postalCode || null
        };
    }

    delete obj.postalCode;

    if (includesPreferences) {
        obj.preferences = {
            interests: obj.preferences
        };
    }

    return obj;
}

/**
 * Sets the initial form data by merging the values from the given data object
 * into the initial state object.
 *
 * @param {object} dataObj - The data object containing key-value pairs to be merged
 *                           into the initial state.
 * @param {object} initialState - The initial state object.
 */
const setInitialFormdata = (dataObj, initialState) => {
    for (const [key, value] of Object.entries(dataObj)) {
        initialState.value = {
            ...initialState.value,
            [key]: value
        }
    }
}

/**
 * Decodes a JWT token and returns the payload as a JSON object.
 *
 * @param {string} token - The JWT token to decode.
 * @return {object} The decoded payload as a JSON object.
 */
const decodeJwtToken = (token) => {
    if (!token) {
        return false;
    }

    // Split the JWT into its three parts: header, payload, and signature
    const jwtParts = token.split('.');
    const jwtPayload = jwtParts[1];

    if (!jwtPayload) {
        return false;
    }

    // Decode the payload from base64Url encoding to JSON format
    return JSON.parse(atob(jwtPayload.replace(/-/g, '+').replace(/_/g, '/')));
}

/**
 * Shows the CMP (Consent Management Platform) screen in advanced mode.
 *
 * @return {type} None - This function does not return any value.
 */
const showCMP = () => {
    trackPageLoad('page_view', {
        custom_page_title: 'Cookie_Preferences_Page'
    });
    __cmp('showScreenAdvanced');
}

/**
 * Determines whether two objects are equal by comparing their properties.
 *
 * @param {object} obj1 - The first object to compare.
 * @param {object} obj2 - The second object to compare.
 * @return {boolean} Returns true if the objects have the same values for all properties, and false otherwise.
 */
const objAreEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Different number of keys, not the same values
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        // Different key, not the same values
        if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
            return false;
        }

        const newValue = obj1[key];
        const oldValue = obj2[key];

        if (Array.isArray(newValue)) {
            // Different array values, not the same values
            if (!Array.isArray(oldValue)) {
                return false;
            }

            // Check if arrays have same length
            if (oldValue.length !== newValue.length) {
                return false;
            }

            // All 2 values are arrays

            // Check if initial value includes new form value
            const arraysAreEqual = newValue.every(item => oldValue?.includes(item));
            if (!arraysAreEqual) {
                return false;
            }
        } else if (typeof newValue === 'object' && !Array.isArray(newValue)) {
            // Different object values, not the same values
            const arraysAreEqual = Object.values(newValue).every(item => Object.values(oldValue)?.includes(item));

            if (!arraysAreEqual) {
                return false;
            }
        } else if (newValue !== oldValue) {
            // Different primitive type values, not the same values
            return false;
        }
    }

    return true; // Objects have the same values
}

/**
 * Sorts an array of objects based on the order of keys provided.
 *
 * @param {Array} arr - The array of objects to be sorted.
 * @param {Array} keyOrder - The order of keys based on which the objects should be sorted.
 * @return {Array} - The sorted array of objects.
 */
const sortObjects = (arr, keyOrder) => {
    arr.sort(function (a, b) {
        let indexA, indexB;

        for (let i = 0; i < keyOrder.length; i++) {
            const key = keyOrder[i];

            if (Object.prototype.hasOwnProperty.call(a, key)) {
                indexA = i;
            }
            if (Object.prototype.hasOwnProperty.call(b, key)) {
                indexB = i;
            }
            if (indexA && indexB) {
                break;
            }
        }
        return indexA - indexB;
    });
    return arr;
}

/**
 * Checks if an object has any non-empty values.
 *
 * @param {Object} obj - The object to be checked.
 * @return {boolean} Returns true if the object has non-empty values, otherwise false.
 */
const objContainsValues = (obj) => {
    for (const key in obj) {
        const hasProperty = Object.prototype.hasOwnProperty.call(obj, key);
        const isNotEmptyString = obj[key] !== '';
        const istNotEmptyArr = obj[key]?.length !== 0;

        if (obj[key] && hasProperty && isNotEmptyString && istNotEmptyArr) {
            return true;
        }
    }

    return false;
}

/**
 * Checks if an object is empty.
 *
 * @param {object} obj - The object to be checked.
 * @return {boolean} Returns true if the object is empty, false otherwise.
 */
const isEmptyObj = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * Checks if a given string is a valid image file based on its file extension.
 *
 * @param {string} thumbnailString - The string representing the file name or path.
 * @return {boolean} Returns true if the string represents a valid image file, otherwise false.
 */
const isTypeImage = (thumbnailString) => {
    if (!thumbnailString) {
        return;
    }
    const parts = thumbnailString.split('.');
    const ext = parts[parts.length - 1];
    const allowedImageExtension = ['png', 'jpg', 'avif', 'webp', 'jpeg', 'PNG', 'JPG', 'AVIF', 'WEBP', 'JPEG'];
    return allowedImageExtension.includes(ext);
}

export {
    insertEllipsisAfterWords,
    getFormattedTrackingData,
    transformObj,
    setInitialFormdata,
    decodeJwtToken,
    showCMP,
    objAreEqual,
    objContainsValues,
    isEmptyObj,
    isTypeImage
}
