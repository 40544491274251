<script setup>
const props = defineProps({
    config: {
        type: Object,
        default: () => {
        }
    }
});

const isSubmitBtn = computed(() => props.config?.type === 'submit')
const isInternalLink = computed(() => props.config?.type === 'internal')
const isExternalLink = computed(() => props.config?.type === 'external')
const isFileDownloadLink = computed(() => props.config?.type === 'file')
const isDiv = computed(() => props.config?.type === 'div');
const customClick = computed(() => props.config?.fn ? 'click' : null)

const buttonClass = computed(() => {
    return {
        'btn-active': props.config?.active,
        'btn-primary': props.config?.buttonType !== 'secondary',
        'btn-secondary': props.config?.buttonType === 'secondary',
        'btn-large': props.config?.large,
        'btn-pending': props.config?.isPending
    }
});

</script>

<template>
    <NuxtLink
        v-if="isInternalLink"
        :to="config.link"
        :class="buttonClass"
        @[customClick]="config.fn"
    >
        <slot />
    </NuxtLink>

    <a
        v-if="isExternalLink"
        :href="config.link"
        :class="buttonClass"
        target="_blank"
        rel="noopener noreferrer"
        @[customClick]="config.fn"
    >
        <slot />
    </a>

    <a
        v-if="isFileDownloadLink"
        :href="config.link"
        :class="buttonClass"
        download
    >
        <slot />
    </a>

    <button
        v-if="isSubmitBtn"
        :disabled="props.disabled"
        :class="buttonClass"
        type="submit"
        @[customClick]="config.fn"
    >
        <slot />
    </button>

    <div
        v-if="isDiv"
        :class="buttonClass"
        @[customClick]="config.fn"
    >
        <slot />
    </div>
</template>

<style lang="scss">
.btn-primary {
    @include btn($type: 'primary');
}

.btn-secondary {
    @include btn($type: 'secondary');
}
</style>
