import { defineStore } from 'pinia';

export const useMessageStore = defineStore('messages', () => {
        // Current messages obj
        const _messages = ref(null);
        const messages = computed(() => _messages.value);

        /**
         * Updates the messages with the given payload.
         *
         * @param {Object} payload - The payload containing the messages to update.
         * @return {undefined} - This function does not return anything.
         */
        const updateMessages = (payload) => {
            if (payload) {
                if (_messages.value) {
                    _messages.value = {
                        ...messages.value,
                        ...payload
                    }
                } else {
                    _messages.value = payload
                }
            } else {
                _messages.value = null;
            }
        }

        return {
            messages,
            updateMessages
        };
    }
);
