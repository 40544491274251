import * as msal from '@azure/msal-browser';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import { trackUserMeta } from '~/composables/useTracking';

export default defineNuxtPlugin(async () => {
    const authStore = useAuthStore();
    const { updateLoggedInStatus, handleUserData } = authStore;
    const { loggedIn } = storeToRefs(authStore);
    const config = useRuntimeConfig();

    const msalConfig = {
        auth: {
            clientId: config.public.azureAdClientId, // Details: App ID of your application. Can be found in your portal registration.
            authority: `https://${config.public.azureAdUrl}.b2clogin.com/${config.public.azureAdUrl}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`, // URI of the tenant to authenticate and authorize with. Usually takes the form of https://{uri}/{tenantid} (see Authority)
            knownAuthorities: [`${config.public.azureAdUrl}.b2clogin.com`], // An array of URIs that are known to be valid. Used in B2C scenarios.
            redirectUri: '/auth', // URI where the authorization code response is sent back to. Whatever location is specified here must have the MSAL library available to handle the response.
            postLogoutRedirectUri: window.location.href
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true,
            secureCookies: true
        },
        telemetry: {
            application: {
                appName: 'MYCE',
                appVersion: '1.0.0'
            }
        }
    }

    const msalInstance = new msal.PublicClientApplication(msalConfig);

    const account = msalInstance.getActiveAccount();

    if (account === null) {
        updateLoggedInStatus(false);
    }

    // handleRedirectPromise will be called automatically after login / redirect to app page
    try {
        const response = await msalInstance.handleRedirectPromise();

        if (response) {
            const accounts = msalInstance?.getAllAccounts();

            if (accounts.length > 0) {
                msalInstance.setActiveAccount(accounts[0]);

                // Get values
                const bearerToken = response?.accessToken;
                const { oid } = decodeJwtToken(bearerToken); // Get oid from helper decodeJwtToken

                // Set cookie for auth middleware
                const authCookie = useCookie('auth-cookie');

                authCookie.value = JSON.stringify({
                    bearerToken
                });

                // Update login status
                updateLoggedInStatus(true);

                // Now set useState for app (store seems to be buggy)
                useState('state_bearerToken', () => bearerToken);
                useState('state_userOid', () => oid);

                if (loggedIn.value) {
                    // Tracks user data
                    trackUserMeta({
                        user_id: oid,
                        user_type: 'known'
                    });

                    // Fetch data from consumer api
                    await handleUserData({
                        method: 'GET',
                        bearerToken,
                        oid,
                        triggerOnBoarding: true
                    });
                }
            }
        }
    } catch (err) {
        throw new Error(`Auth Plugin MSAL handleRedirectPromise error: ${err}`)
    }

    return {
        provide: {
            msal: msalInstance
        }
    }
});
