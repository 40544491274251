import { defineStore, storeToRefs } from 'pinia';
import { useSideBar } from '~/stores/sideBar';
import { useWebview } from '~/stores/webview';
import { signOut } from '~/composables/useMsal';
import { trackUserClick } from '~/composables/useTracking';

export const useAuthStore = defineStore(
    'auth-store',
    () => {
        // Sidebar store
        const sideBarStore = useSideBar();
        const { toggleSideBarLayer } = sideBarStore;

        const webviewStore = useWebview();
        const { webview } = storeToRefs(webviewStore);

        // Current user obj
        const _currentUser = ref(null);
        const currentUser = computed(() => _currentUser.value);

        /**
         * Updates the current user with the given payload.
         *
         * @param {Object} payload - The payload containing the updated user data.
         */
        const updateCurrentUser = (payload) => {
            if (payload) {
                _currentUser.value = {
                    ...payload
                }
            } else {
                _currentUser.value = null;
            }
        };

        // Login status
        const _loggedIn = ref(false);
        const loggedIn = computed(() => _loggedIn.value);

        /**
         * Updates the current user with the given payload.
         *
         * @param {Object} payload - The payload containing the updated user data.
         */
        const updateLoggedInStatus = (payload) => {
            _loggedIn.value = payload;
        };

        // Status object
        const _statusObj = ref(null);
        const statusObj = computed(() => _statusObj.value);

        /**
         * Updates the status object with the given value.
         *
         * @param {any} value - The value to update the status object with.
         */
        const updateStatusObj = (value) => {
            if (value) {
                if (_statusObj.value) {
                    _statusObj.value = {
                        ..._statusObj.value,
                        ...value
                    }
                } else {
                    _statusObj.value = {
                        ...value
                    }
                }
            } else {
                _statusObj.value = null;
            }
        }

        /**
         * Sends tracking events based on the provided tracking payload.
         *
         * @param {object} trackingPayload - The tracking payload containing various events to track.
         */
        const sendTracking = (trackingPayload) => {
            if (trackingPayload) {
                if ('onBoarding' in trackingPayload) {
                    // Track onBoarding
                    trackUserClick('User_Onboarding', trackingPayload.onBoarding);
                }
                if ('preferences' in trackingPayload) {
                    // Track preferences
                    trackUserClick('User_Preferences', trackingPayload.preferences);
                }

                if ('deleteAccount' in trackingPayload) {
                    // Track delete account
                    trackUserClick('User_Delete', trackingPayload.deleteAccount);
                }
            }
        }

        // Get request
        const handleGetRequest = (value) => {
            updateCurrentUser(value);
        }

        /**
         * Handles the update request.
         *
         * @param {string} msg - The message for the update request.
         * @param {boolean} goBack - Indicates whether to go back after the update request.
         */
        const handleUpdateRequest = async (msg, goBack) => {
            // Set status obj + fire GET request
            updateStatusObj({
                success: true,
                message: msg,
                goBack
            });

            // TBD: should be removed! Only here because put gives no response
            await handleUserData(
                {
                    method: 'GET'
                }
            );
        }

        /**
         * Function to handle the delete request.
         *
         * @return {undefined} No return value.
         */
        const handleDeleteRequest = () => {
            // Set localStorage item to show feedback form if user is logged out + redirected
            if (process.client) {
                localStorage.setItem('showFeedbackForm', true);

                // Trigger logout and redirect to current location
                setTimeout(async () => {
                    await signOut();
                }, 1000)
            }
        }

        /**
         * Handle the case when the user account does not exist.
         *
         */
        const handleInvalidUserAccount = () => {
            if (process.client) {
                localStorage.setItem('showSupportNote', true);

                // Trigger logout and redirect to current location
                setTimeout(async () => {
                    await signOut();
                }, 0)
            }
        }

        /**
         * Scrolls smoothly to the specified element.
         *
         * @param {Object} ele - The element to scroll to.
         */
        const scrollSmooth = (ele) => {
            ele.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }

        /**
         * Initializes the onboarding process.
         *
         * @return {undefined} This function does not return anything.
         */
        const initOnboarding = () => {
            // Show account layer every time user logs in (in case user has never entered andy data)
            const keysToInclude = ['firstName'];
            const formValues = [];

            // Check if values of the given keys are empty
            for (const [key, value] of Object.entries(currentUser.value)) {
                if (keysToInclude.includes(key)) {
                    if (value) {
                        formValues.push(value);
                    }
                }
            }

            // Filter duplicates + check if values are contained
            // User has not yet entered first name, so show layer
            const showLayer = [...new Set(formValues)].length < 1;

            // Only in client to avoid hydration mismatch due to setTimeOut
            if (process.client && showLayer) {
                setTimeout(() => {
                     toggleSideBarLayer(showLayer);
                }, 400);
            }
        }

        /**
         * Handles user data based on the provided request configuration.
         *
         * @param {object} reqConfig - The request configuration object.
         *     - bearerToken {string} (optional) - The bearer token.
         *     - correlationId {string} (optional) - The correlation ID.
         *     - oid {string} (optional) - The user OID.
         *     - method {string} - The HTTP method.
         *     - payload {object} - The request payload.
         *     - trackingPayload {object} - The tracking payload.
         *     - successMessage {string} - The success message.
         *     - scrollDiv {string} (optional) - The scroll div.
         * @return {Promise} A promise that resolves with the result of the function.
         */
        const handleUserData = async (reqConfig) => {
            let {
                bearerToken,
                oid
            } = reqConfig;

            const {
                method,
                payload,
                correlationId,
                trackingPayload,
                successMessage,
                scrollDiv,
                triggerOnBoarding
            } = reqConfig;

            bearerToken = bearerToken || useState('state_bearerToken').value;
            oid = oid || useState('state_userOid').value;

            try {
                // Fetch request
                const apiOptions = {
                    GET: '/api/consumers/profile/getProfile',
                    PATCH: '/api/consumers/profile/patchProfile',
                    DELETE: '/api/consumers/profile/deleteProfile'
                };

                // Define request options
                const correlationIdHeader = {
                    'X-Correlation-Id': correlationId
                };

                bearerToken = bearerToken || useState('state_bearerToken').value;
                oid = oid || useState('state_userOid').value;

                const authHeader = {
                    Authorization: `Bearer ${bearerToken}`,
                    'User-Oid': oid
                };

                const headerOptions = correlationId ? { ...authHeader, ...correlationIdHeader } : authHeader;

                const commonOptions = {
                    method,
                    headers: headerOptions
                };

                const patchOptions = {
                    body: JSON.stringify(payload)
                };

                const fetchOptions = method === 'PATCH' ? { ...commonOptions, ...patchOptions } : commonOptions;

                const { data, error } = await useFetch(
                    apiOptions[method],
                    fetchOptions
                );

                // Set pending status
                updateStatusObj({
                    pending: true
                });

                // No error, so patch/delete/get will be successful
                if (!error.value) {
                    // Send tracking if required
                    sendTracking(trackingPayload);

                    // Handle different requests
                    if (method === 'GET' && data.value) {
                        handleGetRequest(data.value?.data);

                        // Check if user has still to be onboarded after login
                        triggerOnBoarding && (initOnboarding());
                    }

                    if (method === 'PATCH') {
                        await handleUpdateRequest(
                            successMessage,
                            reqConfig?.goBack
                        );
                    }

                    if (method === 'DELETE') {
                        handleDeleteRequest();
                    }
                } else {
                    // Login in Azure AD was successful but fetching data from salesforce failed due to user account
                    // Handle this case separately, do not display error message
                    // 500: 'Oops... Something wrong on the server!',
                    const errorCodes = {
                        404: 'The requested resource is not found',
                        410: 'A resource is permanently unavailable on the server and is used to notify recipients to remove links to it.',
                        401: 'UNAUTHORIZED'
                    }

                    const errorId = error.value?.data?.data?.meta?.messages[0]?.id;
                    const userNotValid = Object.keys(errorCodes).includes(errorId);

                    if (userNotValid && !webview.value) {
                        handleInvalidUserAccount();
                    } else {
                        const errorMsgObj = useState('state_errorMsgObj');

                        updateStatusObj({
                            success: false,
                            message: errorMsgObj.value[method]
                        });
                    }
                }
            } catch (err) {
                updateStatusObj({
                    success: false,
                    message: err
                });
            } finally {
                // Remove pending status
                updateStatusObj({
                    pending: false
                });

                // Scroll to top sidebar if scroll container exists
                scrollDiv && (scrollSmooth(scrollDiv));
            }
        }

        return {
            handleUserData,
            currentUser,
            updateLoggedInStatus,
            loggedIn,
            statusObj,
            updateStatusObj
        };
    }
)
