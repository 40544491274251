import { storeToRefs } from 'pinia';
import { useCenterStore } from '~/stores/centerDetails';

export default defineNuxtRouteMiddleware(async (to, from) => {
    const centerStore = useCenterStore();
    const { centerDetails } = storeToRefs(centerStore);
    const { updateCenterDetails } = centerStore;

    // Check if user is on center page
    if (to.params.centerUid) {
        const data = await useFetchCenterDetail(to?.params?.centerUid);

        // If center uid changes push new values to center store
        if (to?.params?.centerUid !== from?.params?.centerUid) {
            updateCenterDetails(data.value);
        } else if (!centerDetails.value) {
            // Push values to center store on first page load
            updateCenterDetails(data.value);
        }
    } else {
        updateCenterDetails(null);
    }
});
