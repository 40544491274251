<script setup>
import { storeToRefs } from 'pinia';
import { useMediaQuery } from '@vueuse/core'
import { useSideBar } from '~/stores/sideBar';
import { useMessageStore } from '~/stores/messages';
import { useCenterStore } from '~/stores/centerDetails';

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Global store
const sideBarStore = useSideBar();
const { sideBarContent } = storeToRefs(sideBarStore);

// Get center details
const { centerUid } = storeToRefs(useCenterStore());

// Get center slug
const { centerRoute } = storeToRefs(useCenterStore());

const centernavigationOpen = useState('centernavigationOpen', () => false)

const handleNavigationClick = () => {
    if (!centernavigationOpen.value) {
        centernavigationOpen.value = true;
        sideBarContent.open = false;
    } else {
        closeCenterNavigation()
    }
}

const closeCenterNavigation = () => {
    if (centernavigationOpen.value) {
        centernavigationOpen.value = false
    }
}

// Close center navigation automatically if side is open
watch([sideBarContent], () => {
    centernavigationOpen.value = false// trigger recomputation
});

// Close center navigation automatically if desktop
const isDesktopNavi = useMediaQuery('(min-width: 1024px)');

watch([isDesktopNavi], () => {
    centernavigationOpen.value = false// trigger recomputation
});
</script>

<template>
    <nav v-if="centerUid" class="page-mainnavigation" :class="{ 'page-mainnavigation-open': centernavigationOpen} ">
        <ul class="page-mainnavigation-list">
            <li class="home-navigation-link">
                <NuxtLink :to="`/${centerRoute}`" class="page-mainnavigation-link" @click="closeCenterNavigation">
                    {{ staticKeys?.header_navigation_home_web }}
                </NuxtLink>
            </li>
            <li>
                <NuxtLink
                    :to="`/${centerRoute}/coupons`" class="page-mainnavigation-link"
                    @click="closeCenterNavigation"
                >
                    {{ staticKeys?.header_navigation_goodies_web }}
                </NuxtLink>
            </li>
            <li>
                <NuxtLink
                    :to="`/${centerRoute}/shops`" class="page-mainnavigation-link"
                    @click="closeCenterNavigation"
                >
                    {{ staticKeys?.header_navigation_hub_web }}
                </NuxtLink>
            </li>
            <!--
            <li>
                <NuxtLink
                    :to="`/${centerRoute}/parken`" class="page-mainnavigation-link"
                    @click="closeCenterNavigation"
                >
                    {{ staticKeys?.header_navigation_parking_web }}
                </NuxtLink>
            </li>-->

            <UserAuthButton class="page-mainnavigation-link" @click="closeCenterNavigation" />

            <li v-if="centernavigationOpen" class="center-selector-link">
                <CenterSelector
                    position="navigation"
                    @update:close-center-navigation="closeCenterNavigation"
                />
            </li>
        </ul>
    </nav>

    <CenterSelector v-if="!centernavigationOpen" :center-uid="centerUid" position="header" />

    <div v-if="centerUid" class="page-mainnavigation-burger" @click="handleNavigationClick">
        <span class="line" />
        <span class="line" />
        <span class="line" />
    </div>
</template>

<style lang="scss">
.page-mainnavigation {
    @include bp(large1) {
        @include content-grid-item(9, 3);

        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 64px;
        color: $page-mainnavigation;
    }
}

.page-mainnavigation-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: fixed;
    z-index: 2;
    inset: 0;
    background-color: $centernavigation-bg;
    backdrop-filter: blur(7px);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    will-change: opacity;
    min-height: 100vh;
    -webkit-overflow-scrolling: auto;
    overflow: auto;

    @include bp(large1) {
        display: contents;
        pointer-events: all;
        min-height: auto;
    }

    .page-mainnavigation-open & {
        display: flex;
        opacity: 1;
        pointer-events: all;
    }

    li {
        display: contents;

        &.center-selector-link {
            margin: auto 0 50px;
            display: block;
        }

        &.home-navigation-link {
            margin-top: 20vh;
            display: block;

            @include bp(large1) {
                margin: 0;
                display: contents;
            }
        }
    }
}

.page-mainnavigation-link {
    @include h1;

    gap: px2rem(40);
    color: $centernavigation-link;
    text-decoration: none;
    display: block;
    transition: color 0.3s ease-out;

    @include bp(medium1) {
        gap: px2rem(48);
    }

    @include bp(large1) {
        @include body2-medium;

        gap: px2rem(64);
        margin-block-end: 0;
    }

    &.router-link-active {
        color: $centernavigation-link-active;
    }

    @media (hover: hover) {
        &:hover {
            color: $centernavigation-link-hover;
        }
    }

    @media (hover: none) {
        &:active {
            color: $centernavigation-link-active;
        }
    }
}

.page-mainnavigation-burger {
    grid-row: 1;
    grid-column: 5 / span 1;
    display: flex;
    flex-flow: row wrap;
    justify-self: flex-end;
    justify-content: flex-end;
    width: px2rem(24);
    position: relative;
    z-index: 3;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    @include bp(medium1) {
        grid-column: 8 / span 1;
    }

    @include bp(large1) {
        display: none;
    }

    .line {
        height: 2px;
        background-color: $centernavigation-burger-dark;
        display: block;
        margin-block: 2.5px;
        transition: all 0.3s ease-in-out;

        @include bp(medium1) {
            background-color: $centernavigation-burger-light;
        }

        &:nth-child(1) {
            width: px2rem(18);
        }

        &:nth-child(2) {
            width: px2rem(24);
        }

        &:nth-child(3) {
            width: px2rem(10);
        }
    }
}

.page-mainnavigation-open {
    ~ .page-mainnavigation-burger {
        animation: smallbig 0.6s forwards;

        .line {
            background-color: $centernavigation-close;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                transition-delay: 0.2s;
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(1) {
                transform: translateY(6px) rotate(45deg);
                width: px2rem(18);
            }

            &:nth-child(3) {
                transform: translateY(-8px) rotate(-45deg);
                width: px2rem(18);
            }
        }
    }
}

@keyframes smallbig {
    0%, 100% {
        transform: scale(1);
    }

    50% {
        transform: scale(0);
    }
}
</style>
