import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/usr/src/app/src/middleware/auth.global.js";
import center_45global from "/usr/src/app/src/middleware/center.global.js";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  center_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-route": () => import("/usr/src/app/src/middleware/check-route.js"),
  webview: () => import("/usr/src/app/src/middleware/webview.js")
}