import { defineStore } from 'pinia';

export const useWebview = defineStore('webview', () => {
        // Default state
        const webview = ref(false);

        /**
         * Sets the value of the webview based on the webviewHeaderValue.
         *
         * @param {string} webviewHeaderValue - The value to set the webview to.
         */
        const setWebview = (webviewHeaderValue) => {
            webview.value = webviewHeaderValue === 'true';
        };

        return {
            webview,
            setWebview
        };
    }
);
