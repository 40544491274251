import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { useCenterStore } from '~/stores/centerDetails';
import { useAuthStore } from '~/stores/auth';

/**
 * Retrieves the cmpConfig from the route or directly from the browser.
 *
 * @return {any} The cmpConfig value.
 */
const getCmpConfig = () => {
    // Get cmpConfig from route or directly from browser
    const route = useRoute();
    const hash = route?.hash;
    const cmpImportHash = hash?.includes('cmpimport');
    const cmpImport = ref();

    if (cmpImportHash) {
        const arr = cmpImportHash.split('=');
        cmpImport.value = arr[1];
    } else {
        cmpImport.value = __cmp('exportConsent');
    }

    return cmpImport.value;
}

/**
 * Sign in to the application.
 *
 * @return {Promise<void>} - Resolves when the sign-in process is complete.
 */
const requestAzure = async (policy) => {
    try {
        const { $msal } = useNuxtApp();
        const config = useRuntimeConfig();
        const { centerUid, centerName } = storeToRefs(useCenterStore());

        const isPasswordReset = policy === 'passwordReset';
        // Set password reset policy
        if (isPasswordReset) {
            $msal.config.auth.authority = `https://${config.public.azureAdUrl}.b2clogin.com/${config.public.azureAdUrl}.onmicrosoft.com/B2C_1A_PASSWORDRESET`;
        }

        const scopes = [
            'openid',
            `https://${config.public?.azureAdUrl}.onmicrosoft.com/apiGateway/accessApiGateway`,
            'offline_access'
        ];

        const signUpOptions = {
            extraQueryParameters: {
                platform: 'webapp',
                center: centerUid.value,
                centerName: centerName.value,
                'X-Correlation-Id': uuidv4(),
                gtmContainerId: config.public?.gtmContainerId,
                '#cmpimport': getCmpConfig()
            },
            scopes
        };

        const standardOptions = {
            scopes
        };

        const options = isPasswordReset ? standardOptions : signUpOptions;

        await $msal.loginRedirect(options);
    } catch (err) {
        // handle error
        console.log(`MSAL err:  ${err}`)
    }
}

/**
 * Acquires an access token silently.
 *
 * @return {Promise<void>} This function does not return anything.
 */
const acquireTokenSilent = async () => {
    const authStore = useAuthStore();
    const { updateLoggedInStatus, handleUserData } = authStore;
    const { loggedIn } = storeToRefs(authStore);

    if (process.client && !loggedIn.value) {
        const { $msal } = useNuxtApp();
        const config = useRuntimeConfig();
        const authCookie = useCookie('auth-cookie');

        const scopes = [
            'openid',
            `https://${config.public.azureAdUrl}.onmicrosoft.com/apiGateway/accessApiGateway`,
            'offline_access'
        ];

        const accounts = $msal.getAllAccounts();

        if (accounts.length > 0) {
            // attempt to acquire a token silently
            try {
                const response = await $msal.acquireTokenSilent({
                    extraQueryParameters: {
                        'X-Correlation-Id': uuidv4()
                    },
                    scopes,
                    account: accounts[0]
                });

                // Get values
                const bearerToken = response?.accessToken;
                const { oid } = decodeJwtToken(bearerToken); // Get oid from helper decodeJwtToken

                // Set cookie for auth middleware
                authCookie.value = JSON.stringify({
                    bearerToken
                });

                // Update login status
                updateLoggedInStatus(true);

                // Now set useState for app (store seems to be buggy)
                useState('state_bearerToken', () => bearerToken);
                useState('state_userOid', () => oid);

                // Fetch data from consumer api
                await handleUserData({
                    method: 'GET',
                    bearerToken,
                    oid,
                    triggerOnBoarding: true,
                    triggerPageReload: true
                });
            } catch (err) {
                // handle error
                // throw new Error(`acquireTokenSilent error: ${err}`)
                // console.log(`acquireTokenSilent error: ${err}`);

                // Delete Cookie
                authCookie.value = null;

                // Sign out user
                await signOut();
            }
        }
    }
}

/**
 * Sign out the user.
 *
 * @param {string} logoutRedirectUri - The URI to redirect to after logout. Defaults to the current window location.
 * @return {Promise<void>} - A promise that resolves when the user has been signed out.
 */
const signOut = async (logoutRedirectUri = window.location.href) => {
    try {
        const { $msal } = useNuxtApp();

        // Delete auth cookies
        const authCookie = useCookie('auth-cookie');
        authCookie.value = null;

        // Invoke logoutRedirect function
        await $msal.logoutRedirect({
            extraQueryParameters: {
                'X-Correlation-Id': uuidv4()
            },
            postLogoutRedirectUri: logoutRedirectUri
        });
    } catch (err) {
        // handle error
        console.log(`Logout err:  ${err}`)
    }
}

export {
    requestAzure,
    signOut,
    acquireTokenSilent
}
