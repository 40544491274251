<script setup>
import { storeToRefs } from 'pinia';
import { useMessageStore } from '~/stores/messages';
import { useWebview } from '~/stores/webview';

// The error object, which contains the status code and message
defineProps({
    error: {
        type: Object,
        required: true
    }
});

const errorHeadlineText = ref('');
const errorButtonText = ref('');

try {
    // Try to fetch message api
    const staticKeys = await useFetchMessages();

    // Fetch messages
    const messagesStore = useMessageStore();
    const { updateMessages } = messagesStore;

    updateMessages(staticKeys.value);

    errorHeadlineText.value = staticKeys.value?.general_error_headline;
    errorButtonText.value = staticKeys.value?.general_error_button;
} catch (error) {
    // If message api is not available set static text keys
    errorHeadlineText.value = 'Fehler';
    errorButtonText.value = 'Alles klar';
}

const errorHeadline = computed(() => errorHeadlineText.value);
const errorButtonTxt = computed(() => errorButtonText.value);

// Clears the error and redirects to the homepage
const handleClearError = () => {
    clearError({ redirect: '/' });
};

const buttonConfig = {
    buttonType: 'primary',
    type: 'div',
    active: true,
    fn: () => handleClearError()
};

// Webview
const webViewStore = useWebview();
const { webview } = storeToRefs(webViewStore);
const isVisible = computed(() => !webview.value);
</script>

<template>
    <div class="error-page-wrapper">
        <PageDefaultHeader v-if="isVisible" />
        <SideBar v-if="isVisible" />

        <div class="error-page">
            <div class="error-page-content">
                <h1>
                    {{ errorHeadline }}
                    <em>{{ error.statusCode }}</em>
                </h1>
                <p>
                    {{ error.message }}
                </p>
                <UiButton :config="buttonConfig">
                    {{ errorButtonTxt }}
                </UiButton>
            </div>
        </div>

        <PageDefaultFooter v-if="isVisible" />
    </div>
</template>

<style lang="scss">
.error-page-wrapper {
    @include page-grid;

    grid-template-rows: auto 1fr auto;
    min-height: 100dvh;
    overflow: hidden;
}

.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    grid-column: 2/-2;
    margin-block-start: px2rem(-40);

    @include bp(large1) {
        margin-block-start: px2rem(-60);
    }
}

.error-page-content {
    max-width: 600px;
    margin: 40px auto;
    text-align: center;

    h1 {
        @include superheadline-h1;

        text-align: center;
        display: inline-block;

        em {
            display: block;
        }
    }

    p {
        @include body1-medium;

        margin: 0 0 30px
    }
}
</style>
