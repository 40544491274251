export default async () => {
    try {
        return await useFetchWithCache('/api/translations/messages');
    } catch (error) {
        // Not needed, just in case...
        throw createError({
            statusCode: 404,
            name: 'NotFoundError',
            message: error,
            statusMessage: 'Error',
            fatal: true
        })
    }
}
