<script setup>
import { storeToRefs } from 'pinia';
import { useSideBar } from '~/stores/sideBar';

// Sidebar store
const sideBarStore = useSideBar();
const { sideBarContent, sideBarOverlayContent, sideBarSubNavContent } = storeToRefs(sideBarStore);
const { toggleSideBar, toggleSideBarSubNav, toggleSideBarOverlay } = sideBarStore;

// Watcher to prevent open sidebar with non-available data on non-center-pages (account, centerdetails)
// User clicks on browser back button
const route = useRoute();

watch(route, (value) => {
    if (value?.name !== 'centerUid') {
        if (sideBarOverlayContent.value.open) {
            if (sideBarOverlayContent.value.content === 'centerDetail') {
                toggleSideBarOverlay({ open: false })
            }
        } else if (sideBarContent.value.open) {
            if (sideBarContent.value.content === 'account') {
                toggleSideBar({ open: false })
            }
        } else if (sideBarSubNavContent.value.open) {
            toggleSideBarSubNav({ open: false }, false)
        }
    }
}, { deep: true, immediate: false })
</script>

<template>
    <LazySideBarContent :data="sideBarContent" :cb="toggleSideBar" />
    <LazySideBarContent :data="sideBarOverlayContent" :cb="toggleSideBarOverlay" />
    <LazySideBarContent :data="sideBarSubNavContent" :is-subnav="true" :cb="toggleSideBarSubNav" />
</template>
