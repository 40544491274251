<script setup>
import { storeToRefs } from 'pinia';
import { useSideBar } from '~/stores/sideBar';
import { useAuthStore } from '~/stores/auth';
import { useMessageStore } from '~/stores/messages';

// Global store
const sideBarStore = useSideBar();
const { toggleSideBar } = sideBarStore;

// Auth store
const authStore = useAuthStore();
const { loggedIn } = storeToRefs(authStore);

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Login label
const loginLabel = computed(() => loggedIn.value ? staticKeys.value?.account_label : staticKeys.value?.header_navigation_login_web);
</script>

<template>
    <li class="user-login">
        <span
            class="page-mainnavigation-link user-login-label"
            @click="toggleSideBar({open: true, content: 'account'})"
        >
            {{ loginLabel }}
        </span>
    </li>
</template>

<style lang="scss">
.user-login {
    cursor: pointer;
}
</style>
