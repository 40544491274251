import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import { acquireTokenSilent } from '~/composables/useMsal';

export default defineNuxtRouteMiddleware(async (to) => {
    // Exclude webviews
    const pagesAreWebviews = to.path?.includes('webviews')
    if (pagesAreWebviews) {
        return;
    }

    const authStore = useAuthStore();
    const { updateLoggedInStatus, handleUserData } = authStore;
    const { loggedIn } = storeToRefs(authStore);

    // Validate token
    // Checks on every route if auth-cookie OR request header is set
    const { data } = await useFetch('/api/validate-token');

    // Get data from validate-token endpoint
    const { bearerToken, oid, isValid } = data.value;

    // Proceed if token is set + is valid
    if (bearerToken && isValid) {
        if (!loggedIn.value) {
            // Set login status to true
            updateLoggedInStatus(true);

            // Now set useState for app (store seems to be buggy)
            useState('state_bearerToken', () => bearerToken);
            useState('state_userOid', () => oid);

            // Fetch data from consumer api
            if (loggedIn.value) {
                await handleUserData({
                    method: 'GET',
                    bearerToken,
                    oid,
                    triggerOnBoarding: true
                });
            }
        }
    } else {
        await acquireTokenSilent();
    }
});
