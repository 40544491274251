<script setup>
import { storeToRefs } from 'pinia';
import { useMessageStore } from '~/stores/messages';
import MyceLogo from '@/assets/logos/myce-logo.svg?component';
import { useCenterStore } from '~/stores/centerDetails';

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Set Link logo, depending on center
const { centerRoute } = storeToRefs(useCenterStore());
const getHomeLink = computed(() => centerRoute.value ? `/${centerRoute.value}/` : '/');
</script>

<template>
    <NuxtLink :to="getHomeLink" class="page-default-layout home-link" :title="staticKeys?.header_logo_alt_web">
        <MyceLogo />
    </NuxtLink>
</template>

<style lang="scss">
.home-link {
    display: block;
    grid-column: 1 / span 2;
    width: px2rem(83);
    height: px2rem(36);
    padding-block: px2rem(11) px2rem(7);
    padding-inline: 1rem 1rem;
    background-color: $page-logo-bg;
    border-radius: px2rem(15);
    z-index: 3;
    text-decoration: none;

    @include bp(medium1) {
        width: px2rem(67);
        margin-block-start: px2rem(10);
        padding: 0;
        background-color: transparent;
        border-radius: 0;
        display: flex;
        align-items: center;
    }

    @include bp(large1) {
        width: px2rem(67);

    }

    > svg {
        width: 100%;
        height: auto;
    }
}
</style>
