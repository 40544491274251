export default async (centerUid) => {
    try {
        return await useFetchWithCache(`/api/center/centers/${centerUid}`);
    } catch (error) {
        // Not needed, just in case...
        throw createError({
            statusCode: 404,
            name: 'NotFoundError',
            message: error,
            statusMessage: 'Error',
            fatal: true
        })
    }
}
