import MarkdownIt from 'markdown-it'

export default defineNuxtPlugin(() => {
    const md = new MarkdownIt({ html: true });
    return {
        provide: {
            // eslint-disable-next-line object-shorthand
            md: md
        }
    }
})
