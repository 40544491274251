<script setup>
import { storeToRefs } from 'pinia';
import Locator from 'assets/icons/locator.svg';
import Home from 'assets/icons/home.svg';
import { useSideBar } from '~/stores/sideBar';
import { useCenterStore } from '~/stores/centerDetails';
import { useMessageStore } from '~/stores/messages';

const props = defineProps({
    position: {
        type: String,
        default: 'content'
    },
    centerUid: {
        type: String,
        default: ''
    }
});

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Get center details
const { centerName } = storeToRefs(useCenterStore());

// Get label
const getCenterSelectorLabel = computed(() => centerName.value ? centerName.value : staticKeys.value?.center_selector_label);

// Get sideBarStatus + toggle function
const sideBarStore = useSideBar();

const { sideBarContent, sideBarOverlayContent } = storeToRefs(sideBarStore);
const { toggleSideBar, toggleSideBarOverlay } = sideBarStore;

// Open center selector, depending on center name
const openCenterSelector = () => {
    if (centerName.value) {
        toggleSideBarOverlay({ open: true, content: 'centerDetail' })
    } else {
        toggleSideBar({ open: true, content: 'centerSelector' })
    }
}

const centerSelectorIsOpen = computed(() => (sideBarContent.value?.open && sideBarContent.value?.content === 'centerSelector') || (sideBarOverlayContent.value?.open && sideBarOverlayContent.value?.content === 'centerDetail'));

const getHeaderCenterSelectorClass = computed(() => {
    return {
        'header-center-selector-active': centerSelectorIsOpen.value,
        'is-center-page': props.centerUid
    }
});

const emit = defineEmits(['update:closeCenterNavigation']);

const handleCenterSelectorClick = () => {
    openCenterSelector()
    emit('update:closeCenterNavigation', true)
}
</script>

<template>
    <div
        v-if="position === 'content'" class="center-selector-wrapper"
        @click="openCenterSelector"
    >
        <div class="center-selector-container">
            <div class="center-selector-label">
                <Locator />
                <span>
                    {{ getCenterSelectorLabel }}
                </span>
            </div>
        </div>
    </div>

    <div
        v-if="position === 'header'" class="header-center-selector" :class="getHeaderCenterSelectorClass"
        :title="getCenterSelectorLabel"
        @click="openCenterSelector()"
    >
        <Home />
    </div>

    <div
        v-if="position === 'navigation'" class="page-mainnavigation-center-selector"
        :title="getCenterSelectorLabel"
        @click="handleCenterSelectorClick"
    >
        <Home />
        <span>
            {{ getCenterSelectorLabel }}
        </span>
    </div>
</template>

<style lang="scss">
.header-center-selector {
    grid-column: 6/span 1;
    grid-row: 1;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include bp(medium1) {
        grid-column: 8/span 1;
    }

    @include bp(large1) {
        grid-column: 12/span 1;
        width: 100%;
    }

    &.is-center-page {
        grid-column: 5/span 1;
        margin-right: 50px;

        @include bp(medium1) {
            grid-column: 8/span 1;
            margin-right: 54px;
        }

        @include bp(large1) {
            grid-column: 12/span 1;
            margin-right: 0;
        }
    }

    &:hover,
    &.header-center-selector-active {
        svg {
            fill: $header-center-selector-active;
            transition: fill 400ms ease-in-out;
        }
    }

    svg {
        fill: $header-center-selector-bright;
        width: 20px;

        @include bp(medium1) {
            fill: $header-center-selector;
        }
    }
}

.center-selector-wrapper {
    border-radius: 16px;
    background: $center-selector-bg;
    height: 80px;
    padding: 0 20px;
    display: flex;
    align-items: center;

    .center-header & {
        min-width: 360px;
    }
}

.center-selector-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.center-selector-label {
    @include body2-extrabold;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;

    svg {
        width: 14px;
        height: 28px;
        margin-right: 40px;
    }

    span {
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 54px);
    }
}

.page-mainnavigation-center-selector {
    @include btn;

    pointer-events: all;

    @include body2-medium;

    border: $page-mainnavigation-center-selector-border 1px solid;
    color: $page-mainnavigation-center-selector;
    cursor: pointer;

    /*    position: fixed;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%); */
    z-index: z('page-mainnavigation-center-selector');
    white-space: nowrap;
}
</style>
